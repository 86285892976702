import React, { useRef, useState, useEffect } from "react";
import axiosRequest from "../../Middleware/api";
import { useSelector, useDispatch } from "react-redux";
import Slider from "react-slick";
import TepsView from "./TepsView";
import ShowDots from "../Tepars/ShowDots";
import { getTepars } from "../../Utils/helpers";
import CommentView from "../Tepars/CommentView";
import { IMAGE, VIDEO } from "../../Utils/enums";
import CommentAction from "../Tepars/CommentAction";
import {
  setSelfContact,
  setTepars,
  handleToast,
  setSound,
} from "../../Redux/actions";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import { useSwiperSlider } from "../../Utils/helpers";
import { LIKED, UNLIKE, ERROR } from "../../Utils/enums";
import "../../assets/css/tepars.scss";
import SliderPopup from "../Tepars/SliderPopup";

function ViewPost({
  post,
  postDetail,
  setSelectedMenu,
  toggleSelfLike,
  onClose,
  showAnimation,
  setShowAnimation,
}) {
  const dispatch = useDispatch();
  const popupRef = useRef(null);
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);
  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const sound = useSelector((state) => state.sound);
  const selfUser = useSelector((state) => state.selfContact);
  const [isReplyId, setIsReplyId] = useState();
  const [isCommentId, setIsCommentId] = useState();
  const [passPostData, setPassPostData] = useState();
  const [isUserPost, setIsUserPost] = useState(false);
  const [mediaAudioShow, setMediaAudioShow] = useState(true);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [isCommentOrReply, setIsCommentOrReply] = useState(false);
  const imagesList = postDetail?.media;

  useEffect(() => {
    const overlayOpenEvent = new Event("overlayOpen");
    document.dispatchEvent(overlayOpenEvent);
  }, []);

  let width = "43.75rem";
  let height = "85vh";

  const viewportWidth = window.innerWidth;
  const viewportHeight = window.innerHeight;

  if (viewportWidth <= 1024 && viewportHeight <= 1380) {
    width = "40rem";
    height = "30rem";
  } else if (viewportWidth <= 1440 || viewportWidth < 1600) {
    width = "60rem";
  } else if (viewportWidth > 1600) {
    width = "60rem";
    height = "40rem";
  }

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  useSwiperSlider(sliderRef, sliderWrapperRef, postDetail.media.length);

  const toggleLike = (postId) => {
    const data = {};
    axiosRequest
      .put(`post/like/${postId}`, data)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = postDetail;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (postDetail?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== selfUser?._id
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          setShowAnimation(true);
          setTimeout(() => {
            setShowAnimation(false);
          }, 1000);
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleClickOutside = (event) => {
    if (popupRef.current === event.target) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDoubleClick = () => {
    if (!postDetail.selfLike) {
      toggleLike(postDetail._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (postDetail?.media?.[mediaIndexFinal]?.type === "Video") {
      setMediaAudioShow(true);
    } else {
      setMediaAudioShow(false);
    }
  }, [postDetail, mediaIndexFinal]);

  return (
    <div className="uploadTepars" style={{ zIndex: 50 }} ref={popupRef}>
      <div
        className="uploadTepars-popup"
        // style={{ width, height }}
      >
        <main className="container" style={{ display: "flex" }}>
          {/* Media Container */}
          <div
            className="upload-image-container"
            style={{
              // width: "450px",
              // height: "562.5px",
              // width: "450px",
              // height: "500px",
              aspectRatio: postDetail.media[0].aspectRatio,
            }}
            ref={sliderWrapperRef}
            onDoubleClick={handleDoubleClick}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />

            {/* show post length */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}

            {mediaAudioShow && (
              <div
                className="image-sound"
                onClick={() => dispatch(setSound(!sound))}
              >
                {sound ? (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Unmute</title>
                    <g id="wpf:mute">
                      <path
                        id="Vector"
                        d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Mute</title>
                    <g clipPath="url(#clip0_1657_59)">
                      <g filter="url(#filter0_d_1657_59)">
                        <path
                          d="M2.9959 21.9013L2.97233 11.224H10.9391L16.2895 5.87351L16.3131 27.2517L10.9626 21.9013H2.9959ZM18.9058 28.1945V25.4604C20.9014 24.879 22.5121 23.7636 23.7377 22.1143C24.9634 20.4651 25.5762 18.6027 25.5762 16.5273C25.5762 14.4531 24.9634 12.5913 23.7377 10.9421C22.5121 9.29277 20.9014 8.1768 18.9058 7.59414L18.9058 4.85999C21.6557 5.47282 23.9027 6.86378 25.6469 9.03287C27.3911 11.202 28.2632 13.7001 28.2632 16.5273C28.2632 19.3557 27.3911 21.8544 25.6469 24.0235C23.9027 26.1926 21.6557 27.5829 18.9058 28.1945ZM18.9058 22.6319L18.9058 19.4264C19.2986 19.0336 19.6208 18.5939 19.8722 18.1074C20.1236 17.6209 20.2493 17.1099 20.2493 16.5744C20.2493 16.0087 20.1277 15.4785 19.8844 14.9839C19.6412 14.4892 19.315 14.0373 18.9058 13.6281L18.9058 10.4226C20.0686 11.0511 21.0271 11.8996 21.7814 12.9682C22.5356 14.0367 22.9127 15.2309 22.9127 16.5508C22.9127 17.8708 22.5356 19.0574 21.7814 20.1109C21.0271 21.1643 20.0686 22.0047 18.9058 22.6319Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1657_59"
                        x="-1.02734"
                        y="2.85938"
                        width="33.291"
                        height="31.3359"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1657_59"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1657_59"
                          result="shape"
                        />
                      </filter>
                      <clipPath id="clip0_1657_59">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            )}

            {/* <div className="gradient" aria-hidden="true" /> */}
            {showAnimation && (
              <div className="doubleTepAnimation">
                <img src={BigTep} alt="Teps" />
              </div>
            )}
          </div>

          <div className="filters">
            {/* header */}
            <div className="header">
              <div className="heading">
                <h1 className="title">View post</h1>
              </div>
              <div
                className="btn btn-back"
                onClick={onClose}
                tabIndex="0"
                aria-label="Back"
                role="button"
              >
                <svg width="20" height="20" viewBox="0 0 30 30" fill="none">
                  <title>Close</title>
                  <g id="Frame 2595">
                    <path
                      id="Shape"
                      d="M24.072 5.69684L24.1924 5.80567C24.7728 6.3861 24.8091 7.30462 24.3012 7.92731L24.1924 8.04771L17.242 14.9981L24.1924 21.9484C24.7728 22.5288 24.8091 23.4473 24.3012 24.07L24.1924 24.1904C23.612 24.7709 22.6934 24.8072 22.0707 24.2993L21.9503 24.1904L15 17.2401L8.04966 24.1904C7.46923 24.7709 6.55071 24.8072 5.92802 24.2993L5.80761 24.1904C5.22718 23.61 5.19091 22.6915 5.69878 22.0688L5.80761 21.9484L12.758 14.9981L5.80761 8.04771C5.22718 7.46729 5.19091 6.54877 5.69878 5.92608L5.80761 5.80567C6.38804 5.22524 7.30656 5.18896 7.92925 5.69684L8.04966 5.80567L15 12.756L21.9503 5.80567C22.5308 5.22524 23.4493 5.18896 24.072 5.69684L24.1924 5.80567L24.072 5.69684Z"
                      fill={
                        theme === "Light"
                          ? "#1891a2"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#1891a2"
                          : "#ffffff"
                      }
                    />
                  </g>
                </svg>
              </div>
            </div>

            <div className="filters-wrapper">
              {/* TEPSVIEW || COMMENTVIEW */}
              {post?.event === "post_like" || post?.type === "PostLike" ? (
                <TepsView
                  post={postDetail}
                  setSelectedMenu={setSelectedMenu}
                  onClose={onClose}
                />
              ) : (
                <CommentView
                  postData={postDetail}
                  setPassPostData={setPassPostData}
                  setIsCommentOrReply={setIsCommentOrReply}
                  isCommentId={isCommentId}
                  setIsCommentId={setIsCommentId}
                  isReplyId={isReplyId}
                  setIsReplyId={setIsReplyId}
                  setIsUserPost={setIsUserPost}
                  isCommentDelete={isCommentDelete}
                  setIsCommentDelete={setIsCommentDelete}
                  isReplyDelete={isReplyDelete}
                  setIsReplyDelete={setIsReplyDelete}
                  goToSlide={goToSlide}
                  setSelectedMenu={setSelectedMenu}
                  notificationComment={post}
                  toggleSelfLike={toggleSelfLike}
                />
              )}
            </div>
          </div>
        </main>
      </div>
      {/* COMMENTACTION */}
      {isCommentOrReply && (
        <CommentAction
          passPostData={passPostData}
          setIsCommentOrReply={setIsCommentOrReply}
          isCommentId={isCommentId}
          setIsCommentId={setIsCommentId}
          isReplyId={isReplyId}
          setIsReplyId={setIsReplyId}
          isUserPost={isUserPost}
          setIsCommentDelete={setIsCommentDelete}
          setIsReplyDelete={setIsReplyDelete}
        />
      )}
    </div>
  );
}

export default ViewPost;
