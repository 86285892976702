import React, { useEffect, useRef, useState, useCallback } from "react";
import Slider from "react-slick";
import { getTepars, useSwiperSlider } from "../../Utils/helpers";
import MediaSliderVideo from "./MediaSliderVideo";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import axiosRequest from "../../Middleware/api";
import { LIKED, UNLIKE, ERROR, VIDEO, IMAGE } from "../../Utils/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelfContact,
  setTepars,
  handleToast,
  setSound,
} from "../../Redux/actions";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "29px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: 0, zIndex: 9 }}
      onClick={onClick}
    />
  );
}

function MediaSlider({ post, showAnimation, setShowAnimation }) {
  const sliderRef = useRef(null);
  const sliderWrapperRef = useRef(null);
  const UserID = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const Tepars = useSelector((state) => state.tepars);
  const toggleViewOtherProfile = useSelector(
    (state) => state.toggleViewOtherProfile
  );
  const selfUser = useSelector((state) => state.selfContact);
  const togglePost = useSelector((state) => state.togglePost);
  const sound = useSelector((state) => state.sound);

  const [mediaIndex, setMediaIndex] = useState(0);
  const [mediaAudioShow, setMediaAudioShow] = useState(false);
  const [isPaused, setIsPaused] = useState({});
  const [isVisible, setIsVisible] = useState(true);
  const [isAppActive, setIsAppActive] = useState(true);
  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  useSwiperSlider(sliderRef, sliderWrapperRef, post?.media?.length);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    verticalSwiping: false,
    appendDots: (dots) => (
      <div style={{ borderRadius: "10px", padding: "5px" }}>
        <ul style={{ margin: "-17px" }}>{dots}</ul>
      </div>
    ),
    afterChange: (currentSlide) => {
      setMediaIndex(currentSlide);
      handleSlideChange(currentSlide);
    },
  };

  // Helper function to safely check media
  const hasValidMedia = (post) => {
    return post?.media && Array.isArray(post.media) && post.media.length > 0;
  };

  const handleSlideChange = useCallback(
    (currentSlide) => {
      pauseAllVideos();
      if (hasValidMedia(post)) {
        var currentMedia = post.media[currentSlide];
      }
      if (hasValidMedia(post) && currentMedia.type === "Video" && isVisible) {
        playVideo(currentMedia._id, currentSlide);
      }
    },
    [post.media, isVisible]
  );

  const playVideo = useCallback(
    (mediaId, index) => {
      const video = document.getElementById(`${post?._id}-${index}`);
      if (video) {
        video
          .play()
          .catch((error) => console.error("Autoplay prevented:", error));
        setIsPaused((prev) => ({ ...prev, [mediaId]: false }));
      }
    },
    [post?._id]
  );

  const handlePlayPauseToggleClick = useCallback(
    (mediaId, index) => {
      const video = document.getElementById(`${post?._id}-${index}`);
      if (video) {
        if (video.paused) {
          video.play();
          setIsPaused((prev) => ({ ...prev, [mediaId]: false }));
        } else {
          video.pause();
          setIsPaused((prev) => ({ ...prev, [mediaId]: true }));
        }
      }
    },
    [post?._id]
  );

  const pauseAllVideos = useCallback(() => {
    if (hasValidMedia(post)) {
      post.media.forEach((media, index) => {
        if (media.type === VIDEO) {
          const video = document.getElementById(`${post?._id}-${index}`);
          if (video) {
            video.pause();
            setIsPaused((prev) => ({ ...prev, [media?._id]: true }));
          }
        }
      });
    }
  }, [post?._id, post?.media]);

  const handleVisibilityChange = useCallback(() => {
    if (document.hidden || !isAppActive || isOverlayOpen) {
      pauseAllVideos();
    } else {
      resumeCurrentVideo();
    }
  }, [pauseAllVideos, isAppActive, isOverlayOpen]);

  const handleAppFocus = useCallback(() => {
    if (
      !toggleViewOtherProfile &&
      togglePost !== "ViewPost" &&
      togglePost !== "EditPost" &&
      togglePost !== "RePost" &&
      !isOverlayOpen
    ) {
      setIsAppActive(true);
      resumeCurrentVideo();
    } else {
      setIsAppActive(false);
      pauseAllVideos();
    }
  }, [pauseAllVideos, togglePost, toggleViewOtherProfile, isOverlayOpen]);

  const handleAppBlur = useCallback(() => {
    setIsAppActive(false);
    pauseAllVideos();
  }, [pauseAllVideos]);

  const handleOverlayOpen = useCallback(() => {
    setIsOverlayOpen(true);
    setIsAppActive(false);
    pauseAllVideos();
  }, [pauseAllVideos]);

  const handleOverlayClose = useCallback(() => {
    setIsOverlayOpen(false);
    setIsAppActive(true);
    if (
      !toggleViewOtherProfile &&
      togglePost !== "ViewPost" &&
      togglePost !== "EditPost" &&
      togglePost !== "RePost"
    ) {
      resumeCurrentVideo();
    }
  }, [pauseAllVideos, togglePost, toggleViewOtherProfile]);

  const resumeCurrentVideo = useCallback(() => {
    if (hasValidMedia(post)) {
      var currentMedia = post?.media[mediaIndex];
    }
    if (hasValidMedia(post) && currentMedia?.type === VIDEO) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
              const video = entry.target;
              if (video.paused) {
                video
                  .play()
                  .catch((error) =>
                    console.error("Autoplay prevented:", error)
                  );
                setIsPaused((prev) => ({
                  ...prev,
                  [video.dataset.mediaId]: false,
                }));
              }
            } else {
              const video = entry.target;
              if (!video.paused) {
                video.pause();
                setIsPaused((prev) => ({
                  ...prev,
                  [video.dataset.mediaId]: true,
                }));
              }
            }
          });
        },
        { threshold: 0.8 }
      );
      const videos = document.querySelectorAll(".view-video");
      videos.forEach((video) => observer.observe(video));

      return () => observer.disconnect();
    }
  }, [pauseAllVideos]);

  useEffect(() => {
    if (
      toggleViewOtherProfile ||
      togglePost === "ViewPost" ||
      togglePost === "EditPost" ||
      togglePost === "RePost" ||
      isOverlayOpen
    ) {
      pauseAllVideos();
    } else {
      resumeCurrentVideo();
    }
  }, [togglePost, toggleViewOtherProfile, isOverlayOpen]);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("focus", handleAppFocus);
    window.addEventListener("blur", handleAppBlur);
    document.addEventListener("overlayOpen", handleOverlayOpen);
    document.addEventListener("overlayClose", handleOverlayClose);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("focus", handleAppFocus);
      window.removeEventListener("blur", handleAppBlur);
      document.removeEventListener("overlayOpen", handleOverlayOpen);
      document.removeEventListener("overlayClose", handleOverlayClose);
    };
  }, [
    handleVisibilityChange,
    handleAppFocus,
    handleAppBlur,
    handleOverlayOpen,
    handleOverlayClose,
  ]);

  useEffect(() => {
    const overlayCloseEvent = new Event("overlayClose");
    document.dispatchEvent(overlayCloseEvent);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio >= 0.8) {
            setIsVisible(true);
            if (post?.media?.[mediaIndex]?.type === "Video") {
              playVideo(post?.media[mediaIndex]?._id, mediaIndex);
            }
          } else {
            setIsVisible(false);
            pauseAllVideos();
          }
        });
      },
      { threshold: 0.8 }
    );

    if (sliderWrapperRef.current) {
      observer.observe(sliderWrapperRef.current);
    }
    if (hasValidMedia(post) && post.media[mediaIndex].type === "Video") {
      setMediaAudioShow(true);
    } else {
      setMediaAudioShow(false);
    }

    return () => {
      if (sliderWrapperRef.current) {
        observer.unobserve(sliderWrapperRef.current);
      }
    };
  }, [playVideo, pauseAllVideos, post.media, mediaIndex]);

  useEffect(() => {
    return () => {
      pauseAllVideos();
    };
  }, [pauseAllVideos, post?._id]);

  // const calculateHeight = (aspectRatio) => {
  //   if (aspectRatio) {
  //     // if (aspectRatio < 1) {
  //     //   return 1 * 450;
  //     // } else {
  //       return aspectRatio * 450;
  //     // }
  //   }
  //   return "auto";
  // };

  const calculateHeight = (postData) => {
    var allVideo = postData?.media?.every((item) => item?.type === "Video")
      ? VIDEO
      : IMAGE;
    if (postData) {
      if (postData?.media?.length === 1 && postData?.media[0]?.type === VIDEO) {
        return 1.25 * 450;
      } else if (
        postData?.media?.length === 1 &&
        postData?.media[0]?.type === IMAGE
      ) {
        return postData?.media[0]?.aspectRatio * 450;
      } else if (postData?.media?.length > 1 && allVideo === VIDEO) {
        return 1.25 * 450;
      } else {
        return postData?.media[0]?.aspectRatio * 450;
      }
    }
    return "auto";
  };

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  const toggleLike = (postId) => {
    const data = {};
    axiosRequest
      .put(`post/like/${postId}`, data)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps + 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...selfUser,
              Teps: selfUser?.Teps - 1,
            };
            dispatch(setSelfContact(updatedUser));
          }
          let newpost = post;
          const newObj = {
            userId: {
              _id: selfUser?._id,
              name: "You",
              profile: selfUser?.profile,
              phone: selfUser?.phone,
              countryCode: selfUser?.countryCode,
              contactStatus: selfUser?.isUserVerified,
            },
          };
          if (post?.selfLike) {
            newpost.TotalLike -= 1;
            newpost.selfLike = false;
            newpost.likeUser = newpost?.likeUser?.filter(
              (item) => item?.userId?._id !== selfUser?._id
            );
          } else {
            newpost.TotalLike += 1;
            newpost.selfLike = true;
            newpost.likeUser = [...(newpost?.likeUser || []), newObj];
          }
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newpost : Post
          );
          dispatch(setTepars(findPost));
          setShowAnimation(true);
          setTimeout(() => {
            setShowAnimation(false);
          }, 1000);
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleDoubleClick = () => {
    if (!post.selfLike) {
      toggleLike(post._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  return (
    <>
      <div
        className="post-media-wrapper"
        onDoubleClick={handleDoubleClick}
        ref={sliderWrapperRef}
      >
        <Slider {...settings} ref={sliderRef}>
          {post?.media?.map((Media, index) => (
            <React.Fragment key={index}>
              {Media?.type === "Video" ? (
                <div style={{ position: "relative" }} key={index}>
                  <div
                    className="post-media"
                    style={{ height: calculateHeight(post) }}
                  >
                    <MediaSliderVideo
                      index={index}
                      post={post}
                      Media={Media}
                      mediaIndexFinal={mediaIndex}
                      handlePlayPauseToggleClick={handlePlayPauseToggleClick}
                      disableRightClick={disableRightClick}
                      setIsPaused={setIsPaused}
                    />
                  </div>
                  {isPaused[Media._id] && index === mediaIndex && (
                    <div
                      className={`video ${isPaused}`}
                      onClick={() =>
                        handlePlayPauseToggleClick(Media?._id, index)
                      }
                      onContextMenu={disableRightClick}
                      title="Play"
                    >
                      <svg
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                      >
                        <title>Play</title>
                        <g id="line-md:play-to-pause-transition">
                          <path
                            id="Vector"
                            d="M1.83399 11.0104L1.83398 17.8482C1.83398 19.6325 3.97888 20.7407 5.69234 19.8541L12.2873 16.4297L18.8822 13.0053C20.5957 12.1187 20.5957 9.89112 18.8822 8.99345L12.2873 5.56901L5.69234 2.14456C3.97889 1.25798 1.83399 2.36621 1.83399 4.15047L1.83399 10.9883L1.83399 11.0104Z"
                            fill="white"
                          />
                        </g>
                      </svg>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  key={index}
                  className={`post-media ${Media?.type}`}
                  style={{ height: calculateHeight(post) }}
                >
                  <img
                    src={getTepars(Media)}
                    alt=""
                    onContextMenu={disableRightClick}
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </Slider>
        {post?.media?.length > 1 && (
          <div className="image-number">
            <span className="number">
              {`${mediaIndex + 1}/${post?.media?.length}`}
            </span>
          </div>
        )}
        {mediaAudioShow && (
          <div
            className="image-sound"
            onClick={() => dispatch(setSound(!sound))}
          >
            {sound ? (
              <svg viewBox="0 0 32 32" fill="none">
                <title>Unmute</title>
                <g id="wpf:mute">
                  <path
                    id="Vector"
                    d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                    fill="white"
                  />
                </g>
              </svg>
            ) : (
              <svg viewBox="0 0 32 32" fill="none">
                <title>Mute</title>
                <g clipPath="url(#clip0_1657_59)">
                  <g filter="url(#filter0_d_1657_59)">
                    <path
                      d="M2.9959 21.9013L2.97233 11.224H10.9391L16.2895 5.87351L16.3131 27.2517L10.9626 21.9013H2.9959ZM18.9058 28.1945V25.4604C20.9014 24.879 22.5121 23.7636 23.7377 22.1143C24.9634 20.4651 25.5762 18.6027 25.5762 16.5273C25.5762 14.4531 24.9634 12.5913 23.7377 10.9421C22.5121 9.29277 20.9014 8.1768 18.9058 7.59414L18.9058 4.85999C21.6557 5.47282 23.9027 6.86378 25.6469 9.03287C27.3911 11.202 28.2632 13.7001 28.2632 16.5273C28.2632 19.3557 27.3911 21.8544 25.6469 24.0235C23.9027 26.1926 21.6557 27.5829 18.9058 28.1945ZM18.9058 22.6319L18.9058 19.4264C19.2986 19.0336 19.6208 18.5939 19.8722 18.1074C20.1236 17.6209 20.2493 17.1099 20.2493 16.5744C20.2493 16.0087 20.1277 15.4785 19.8844 14.9839C19.6412 14.4892 19.315 14.0373 18.9058 13.6281L18.9058 10.4226C20.0686 11.0511 21.0271 11.8996 21.7814 12.9682C22.5356 14.0367 22.9127 15.2309 22.9127 16.5508C22.9127 17.8708 22.5356 19.0574 21.7814 20.1109C21.0271 21.1643 20.0686 22.0047 18.9058 22.6319Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_1657_59"
                    x="-1.02734"
                    y="2.85938"
                    width="33.291"
                    height="31.3359"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_1657_59"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_1657_59"
                      result="shape"
                    />
                  </filter>
                  <clipPath id="clip0_1657_59">
                    <rect width="32" height="32" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        )}

        {showAnimation && (
          <div className="doubleTepAnimation">
            <img src={BigTep} alt="Teps" />
          </div>
        )}
      </div>
    </>
  );
}
export default MediaSlider;
