import React, { useEffect, useRef, useState } from "react";
import WhyPost from "./WhyPost";
import Slider from "react-slick";
import MenuPopup from "./MenuPopup";
import ReportPost from "./ReportPost";
import PostLikeUser from "./PostLikes";
import Spinner from "../Common/Spinner";
import CommentAction from "./CommentAction";
import ComingSoon from "../Common/ComingSoon";
import axiosRequest from "../../Middleware/api";
import { useDispatch, useSelector } from "react-redux";
import { useSwiperSlider } from "../../Utils/helpers";
import BigTep from "../../assets/image/SVG_Images/BigTep.svg";
import like from "../../assets/image/SVG_Images/like.svg";
import unlike from "../../assets/image/SVG_Images/unlike.svg";
import unlikeGrey from "../../assets/image/SVG_Images/unlikeGrey.svg";
import {
  ERROR,
  COMMENT_ERROR,
  IMAGE,
  LIKED,
  UNLIKE,
  VIDEO,
} from "../../Utils/enums";
import { post_image__url } from "../../ServerConfiguration/awsConfiguration";
import {
  getTepars,
  getSelfUser,
  getUserProfilePic,
  getSponsorProfilePic,
  convertToAgoFormat,
} from "../../Utils/helpers";
import {
  setTepars,
  handleToast,
  setPostDetails,
  setTogglePostAction,
  incrementPostComments,
  setUser,
  setSound,
} from "../../Redux/actions";
import ShowDots from "./ShowDots";
import SliderPopup from "./SliderPopup";
import "../../assets/css/profilePostView.scss";

const CommentLikeAndCount = ({
  postData,
  comment,
  toggleComment,
  handleReplyClick,
  commentActions,
}) => {
  const UserID = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const [selfLike, setSelfLike] = useState(comment?.selfLike);
  const [likeCount, setLikeCount] = useState(comment?.likeUser);

  return (
    <div className="profile-cta">
      <div className="profile-cta-likes">
        <div
          className="likes-image"
          role="button"
          tabIndex="0"
          onClick={() => {
            setSelfLike(!selfLike);
            setLikeCount(selfLike ? likeCount - 1 : likeCount + 1);
            toggleComment(postData?._id, comment?.commentId);
          }}
        >
          <img
            src={
              selfLike
                ? like
                : theme === "Light" ||
                  (theme !== "Dark" &&
                    window.matchMedia("(prefers-color-scheme: light)").matches)
                ? unlike
                : unlikeGrey
            }
            className={selfLike ? "animate" : ""}
            title={selfLike ? "Unteps" : "Teps"}
            alt="Teps"
          />
        </div>
        {likeCount > 0 && <span className="comments-likes">{likeCount}</span>}
      </div>
      {comment?.replayStatus || comment?.userId?._id === UserID ? (
        <div
          className="comment-reply"
          tabIndex="0"
          onClick={() =>
            handleReplyClick(
              comment?.commentId,
              comment?.userId?.name,
              comment?.userId?._id
            )
          }
        >
          <span>Reply</span>
        </div>
      ) : null}

      <div
        className="comment-cta"
        title="Comment options"
        tabIndex="0"
        aria-label="comment options"
        onClick={() => commentActions(comment, postData)}
      >
        <svg width="11" height="3" viewBox="0 0 11 3" fill="none">
          <title>Comment options</title>
          <circle cx="1.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="5.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="9.5" cy="1.5" r="1.5" fill="#999999" />
        </svg>
      </div>
    </div>
  );
};

const RepliesLikeAndCount = ({
  index,
  postData,
  comment,
  reply,
  handleReplyClick,
  toggleCommentReply,
  replyActions,
  handleViewMoreReplies,
}) => {
  const UserID = localStorage.getItem("user_id");
  const theme = useSelector((state) => state.theme);

  const [selfReplyLike, setSelfReplyLike] = useState(reply.selfLike);
  const [replyLikeCount, setReplyLikeCount] = useState(reply?.likeUser);

  return (
    <div className="profile-cta" key={index}>
      <div className="profile-cta-likes">
        <div
          className="likes-image"
          role="button"
          tabIndex="0"
          onClick={() => {
            setSelfReplyLike(!selfReplyLike);
            setReplyLikeCount(
              selfReplyLike ? replyLikeCount - 1 : replyLikeCount + 1
            );
            toggleCommentReply(postData?._id, comment?.commentId, reply?._id);
          }}
        >
          <img
            src={
              selfReplyLike
                ? like
                : theme === "Light" ||
                  (theme !== "Dark" &&
                    window.matchMedia("(prefers-color-scheme: light)").matches)
                ? unlike
                : unlikeGrey
            }
            className={selfReplyLike ? "animate" : ""}
            title={selfReplyLike ? "Unteps" : "Teps"}
            alt="Teps"
          />
        </div>
        {replyLikeCount > 0 && (
          <span className="comments-likes">{replyLikeCount}</span>
        )}
      </div>

      {reply?.replayStatus || reply?.userId?._id === UserID ? (
        <div
          className="comment-reply"
          tabIndex="0"
          onClick={() =>
            handleReplyClick(
              comment?.commentId,
              reply?.taguser?.name,
              reply?.userId?._id,
              reply?.userId?.name
            )
          }
        >
          <span>Reply</span>
        </div>
      ) : null}

      <div
        className="reply-cta"
        tabIndex="0"
        aria-label="Reply options"
        onClick={() => {
          replyActions(postData, comment, reply);
          // if (comment?.replies?.length === 1 && comment?.replyCount !== 1) {
          //   handleViewMoreReplies(comment?.commentId);
          // }
        }}
      >
        <svg width="11" height="3" viewBox="0 0 11 3" fill="none">
          <title>Comment options</title>
          <circle cx="1.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="5.5" cy="1.5" r="1.5" fill="#999999" />
          <circle cx="9.5" cy="1.5" r="1.5" fill="#999999" />
        </svg>
      </div>
    </div>
  );
};

function ProfilePostView({ setPostDetailsView, onClose }) {
  const dispatch = useDispatch();
  const profilePostViewRef = useRef(null);
  const replyRef = useRef(null);
  const videoRef = useRef(null);
  const sliderRef = useRef(null);
  const fileInputRef = useRef(null);
  const containerRef = useRef(null);

  const sliderWrapperRef = useRef(null);

  const UserID = localStorage.getItem("user_id");

  const user = useSelector((state) => state.user);
  const theme = useSelector((state) => state.theme);
  const Tepars = useSelector((state) => state.tepars);
  const sound = useSelector((state) => state.sound);
  const User = useSelector((state) => state.selfContact);
  const post = useSelector((state) => state.otherPostDetails);

  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const [toReplyId, setToReplyId] = useState();
  const [isReplyId, setIsReplyId] = useState();
  const [toReply, setToReply] = useState(false);
  const [postWhy, setPostWhy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [postMenu, setPostMenu] = useState(false);
  const [toTagUserId, setToTagUserId] = useState();
  const [toReplyName, setToReplyName] = useState();
  const [isCommentId, setIsCommentId] = useState();
  const [postLikes, setPostLikes] = useState(false);
  const [leftClick, setLeftClick] = useState(false);
  const [repliesPage, setRepliesPage] = useState([]);
  const [aspectRatio, setAspectRatio] = useState("");
  const [isUserPost, setIsUserPost] = useState(false);
  const [reportMenu, setReportMenu] = useState(false);
  const [rightClick, setRightClick] = useState(false);
  const [writeComment, setWriteComment] = useState("");
  const [toTagUserName, setToTagUserName] = useState();
  const [postComments, setPostComments] = useState([]);
  const [passPostData, setPassPostData] = useState(post);
  const [showAnimation, setShowAnimation] = useState(false);
  const [getCurrentIndex, setGetCurrentIndex] = useState(0);
  const [mediaIndexFinal, setMediaIndexFinal] = useState(0);
  const [isReplyDelete, setIsReplyDelete] = useState(false);
  const [commentLoading, setCommentLoading] = useState(false);
  const [selectPostID, setSelectPostID] = useState(post?._id);
  const [repliesPageCount, setRepliesPageCount] = useState(0);
  const [showReplyPopup, setShowReplyPopup] = useState(false);
  const [newReplyComments, setNewReplyComments] = useState([]);
  const [isCommentDelete, setIsCommentDelete] = useState(false);
  const [appendPostComments, setAppendPostComments] = useState();
  const [mediaAudioShow, setMediaAudioShow] = useState(true);
  const [isCommentOrReply, setIsCommentOrReply] = useState(false);
  const [isCommentAndReplyId, setIsCommentAndReplyId] = useState();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [reportCommentAndReply, setReportCommentAndReply] = useState(false);
  const [selectedTeparsImage, setSelectedTeparsImage] = useState(
    post?.media[0]
  );
  const [videoDimensions, setVideoDimensions] = useState({
    width: 0,
    height: 0,
  });
  const imagesList = post?.media;
  const isSelfPost = post?.userId?._id === UserID;
  const [videoPause, setVideoPause] = useState(false);
  const [wasPlaying, setWasPlaying] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const isAdminPost = post?.userId?.userType === "Admin";
  const isCircleGroupMember = post?.group?.[0]?.GroupId?.userRole === "Member";
  const isNormalOrNormalGroup =
    !post?.group || post?.group[0]?.GroupId?.groupType === "Normal";
  const isCircleGroup = post?.group?.[0]?.GroupId?.groupType === "Circle";

  // const handlePlayPauseToggleClick = (e) => {
  //   const video = document.getElementById(e);
  //   if (video && video.readyState === 4) {
  //     video.paused ? video.play() : video.pause();
  //     setVideoPause(video.paused);
  //   }
  // };

  // useEffect(() => {
  //   const currentMedia = imagesList[mediaIndexFinal];

  //   if (currentMedia?.type === VIDEO) {
  //     videoRef.current.play();
  //   }
  // }, [mediaIndexFinal]);

  // const handleVisibilityChange = () => {
  //   if (document.hidden) {
  //     if (
  //       imagesList[mediaIndexFinal]?.type === VIDEO &&
  //       !videoRef.current.paused
  //     ) {
  //       videoRef.current.pause();
  //       setWasPlaying(true);
  //     } else {
  //       setWasPlaying(false);
  //     }
  //   } else if (wasPlaying) {
  //     videoRef.current.play();
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [wasPlaying]);

  // const handleSlideChange = (currentSlide) => {
  //   const currentMedia = imagesList[currentSlide];
  //   const isVideo = currentMedia?.type === VIDEO;
  //   const video = document.getElementById(
  //     `${currentMedia?._id}-${currentSlide}`
  //   );

  //   // Pause previous video
  //   if (currentVideo && currentVideo !== video) {
  //     currentVideo.pause();
  //   }

  //   // Play new video if it exists
  //   if (isVideo && video) {
  //     video
  //       .play()
  //       .then(() => {
  //         setCurrentVideo(video);
  //         setVideoPause(false);
  //       })
  //       .catch((error) => {
  //         console.log("Auto-play failed:", error);
  //         setVideoPause(true);
  //       });
  //   }

  //   setMediaIndexFinal(currentSlide);
  // };

  // Add effect to handle initial video autoplay
  useEffect(() => {
    if (post?.media?.[0]?.type === VIDEO) {
      const firstVideo = document.getElementById(`${post.media[0]._id}-0`);
      if (firstVideo) {
        firstVideo
          .play()
          .then(() => {
            setCurrentVideo(firstVideo);
            setVideoPause(false);
          })
          .catch((error) => {
            console.log("Initial auto-play failed:", error);
            setVideoPause(true);
          });
      }
    }
  }, [post]);

  const disableRightClick = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (post && post.length > 0) {
      const currentMedia = post[0];
      updateDimensions(currentMedia);
    }
  }, [post]);

  const updateDimensions = (media) => {
    const isVideo = media.type === "Video";
    const mediaUrl = getTepars(media);

    if (isVideo) {
      const video = document.createElement("video");
      video.src = mediaUrl;

      video.onloadedmetadata = () => {
        const width = video.videoWidth;
        const height = video.videoHeight;
        calculateDimensions(width, height);
      };
    } else {
      const img = new Image();
      img.src = mediaUrl;
      img.onload = () => {
        const width = img.width;
        const height = img.height;
        calculateDimensions(width, height);
      };
    }
  };

  const calculateDimensions = (width, height) => {
    setDimensions({ width, height });

    const gcd = (a, b) => (b === 0 ? a : gcd(b, a % b));

    const divisor = gcd(width, height);
    const ratioWidth = width / divisor;
    const ratioHeight = height / divisor;

    setAspectRatio(`${ratioWidth}:${ratioHeight}`);
  };

  const handleLoadedMetadata = (event) => {
    setVideoDimensions({
      width: event.target.videoWidth,
      height: event.target.videoHeight,
    });
  };

  // const handleCropPlayPauseToggle = (e, index) => {
  //   const videoElement = e.target;
  //   if (videoElement.paused) {
  //     // Pause all other videos
  //     post?.media?.forEach((_, i) => {
  //       if (i !== index) {
  //         const otherVideo = document.querySelector(`video[data-index="${i}"]`);
  //         if (otherVideo) {
  //           otherVideo.pause();
  //         }
  //       }
  //     });
  //     videoElement.play();
  //   } else {
  //     videoElement.pause();
  //   }
  // };

  const getObjectFit = () => {
    return videoDimensions.height < videoDimensions.width
      ? post[0]?.aspectRatio === 0.5
        ? "cover"
        : "contain"
      : "cover";
  };

  useEffect(() => {
    if (postComments) {
      setAppendPostComments(postComments);
    }
  }, [postComments]);

  useEffect(() => {
    const newCmtData = appendPostComments?.filter(
      (items) => items?.commentId !== isCommentId
    );
    setPostComments(newCmtData);
    setIsCommentDelete(false);
  }, [isCommentDelete]);

  useEffect(() => {
    if (isReplyDelete) {
      const newReplyData = appendPostComments?.map((comment) => {
        if (comment?.commentId === isCommentId) {
          const filterReply = comment?.replies?.filter(
            (reply) => reply?._id !== isReplyId
          );
          return {
            ...comment,
            replies: filterReply,
            replyCount: comment?.replyCount - 1,
          };
        }
        return comment;
      });
      setPostComments(newReplyData);
      setIsReplyDelete(false);
      setIsReplyId();
    }
  }, [isReplyDelete]);

  const handleReplyClick = (id, name, tagUserIdData, tagUserName) => {
    setToReplyId(id);
    setToReplyName(name);
    setToTagUserId(tagUserIdData);
    setToTagUserName(tagUserName);
    setToReply(true);
  };

  const toggleCommentReply = (postId, cmtId, replyId) => {
    const data = {
      commentId: cmtId,
      replyId: replyId,
    };
    axiosRequest
      .put(`post/comment/reply/like/${postId}`, data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const replyActions = (postData, comment, reply) => {
    setIsCommentOrReply(true);
    setPassPostData(postData);
    setIsCommentId(comment?.commentId);
    setIsReplyId(reply?._id);
    if (postData?.userId?._id === UserID) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== UserID &&
      reply?.userId?._id === UserID
    ) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== UserID &&
      reply?.userId?._id !== UserID
    ) {
      setIsUserPost(false);
    }
  };

  const commentActions = (comment, postData) => {
    setIsCommentOrReply(true);
    setPassPostData(postData);
    setIsCommentId(comment?.commentId);
    if (postData?.userId?._id === UserID) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== UserID &&
      comment?.userId?._id === UserID
    ) {
      setIsUserPost(true);
    } else if (
      postData?.userId?._id !== UserID &&
      comment?.userId?._id !== UserID
    ) {
      setIsUserPost(false);
    }
  };

  const handleRepostModal = () => {
    onClose();
    dispatch(setPostDetails(post));
    dispatch(setTogglePostAction("RePost"));
  };

  useEffect(() => {
    // fileInputRef.current.focus();
    if (toReplyId !== undefined) {
      fileInputRef.current.focus();
    }
  }, [toReplyId]);

  const renderLikeInfo = () => {
    if (
      isAdminPost ||
      (post?.group && post?.group[0]?.GroupId?.userRole && isCircleGroupMember)
    ) {
      if (post?.TotalLike > 0) {
        return (
          <p className="users-liked-info">
            <span>{post?.TotalLike}</span> Teps
          </p>
        );
      }
    } else if (post?.TotalLike === 1) {
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info">
            <span>{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{post?.likeUser[0]?.userId?.name}</span> Teps this
          </p>
        );
      }
    } else if (post?.TotalLike > 0) {
      const userLiked = post?.likeUser?.some(
        (likes) => likes?.userId?._id === UserID
      );
      const otherLikes = post?.TotalLike - 1;

      if (userLiked) {
        return (
          <p className="users-liked-info">
            <span>You</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {otherLikes > 1 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
      if (
        post?.likeUser[0]?.userId?.contactStatus ||
        post?.likeUser[0]?.userId._id === UserID
      ) {
        return (
          <p className="users-liked-info">
            <span>{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      } else {
        return (
          <p className="users-liked-info">
            <span>~{post?.likeUser[0]?.userId?.name}</span> and{" "}
            <span>
              {post?.hideLikeCount !== true && otherLikes}
              {post?.TotalLike > 2 ? " others " : " other "}
            </span>
            Teps this
          </p>
        );
      }
    }
    return null;
  };

  const commentAndRepliesDataAppend = async () => {
    if (toReply) {
      const sendComment = {
        text: writeComment,
        commentId: toReplyId,
        taguser: toTagUserId ? toTagUserId : User?._id,
        type: "message",
      };

      axiosRequest
        .put(`post/comment/reply/${post?._id}`, sendComment)
        .then((res) => {
          let onlyNewReplyData = [res?.data?.data, ...newReplyComments];
          setNewReplyComments(onlyNewReplyData);
          const foundComment = appendPostComments.find(
            (comment) => comment.commentId === toReplyId
          );

          if (foundComment.replies.length > 0) {
            var OnlyNewReplyData = [res?.data?.data, ...foundComment?.replies];
          } else {
            OnlyNewReplyData = [res?.data?.data];
          }

          const updatedComments = appendPostComments.map((comment) => {
            if (comment.commentId === toReplyId) {
              return {
                ...comment,
                replies: OnlyNewReplyData,
              };
            }
            return comment;
          });
          setAppendPostComments(updatedComments);
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
          }
        });

      setToReplyId();
      setToReplyName();
      setToTagUserId();
      setToReply(false);
      setWriteComment("");
    } else {
      const sendComment = {
        text: writeComment,
        type: "message",
      };

      axiosRequest
        .put(`post/comment/${post?._id}`, sendComment)
        .then((res) => {
          if (res?.data?.data) {
            let getAppended = [res.data.data, ...appendPostComments];
            setAppendPostComments(getAppended);
            dispatch(incrementPostComments(post?._id));
          } else {
            dispatch(handleToast(res?.data?.message));
          }
        })
        .catch((error) => {
          console.log(error);
          if (error?.response?.status !== 401) {
            dispatch(handleToast(ERROR));
          }
        });
    }
    fileInputRef.current.textContent = "";
    setWriteComment("");
  };

  const fetchComments = (pageNumber) => {
    setLoading(true);
    if (pageNumber === 1) {
      setCommentLoading(true);
    }
    axiosRequest
      .get(`post/web/maincomment/${post?._id}?page=${pageNumber}`)
      .then((res) => {
        setCount(res.data.totalCount);
        if (res?.data?.data?.length) {
          if (pageNumber === 1) {
            setPostComments(res?.data?.data);
          } else {
            setPostComments((prevTepars) => [
              ...prevTepars,
              ...res?.data?.data,
            ]);
          }
        }
        setPage(pageNumber + 1);
        setLoading(false);
        setCommentLoading(false);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(COMMENT_ERROR));
        }
        setLoading(false);
        setCommentLoading(false);
      });
  };

  useEffect(() => {
    fetchComments(1);
  }, []);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (
      scrollTop + clientHeight >= scrollHeight - 15 &&
      !loading &&
      count !== appendPostComments.length
    ) {
      fetchComments(page);
    }
  };

  const handleViewMoreReplies = (commentId) => {
    let pageNumber = 0;
    if (repliesPage.length) {
      let data = repliesPage.find((reply) => reply.id === commentId);

      if (data) {
        setRepliesPage(
          repliesPage.map((reply) => {
            if (reply.id === commentId) {
              return {
                id: commentId,
                pageNumber: data.pageNumber + 1,
              };
            } else {
              return reply;
            }
          })
        );
        pageNumber = data.pageNumber + 1;
      } else {
        setRepliesPage([
          ...repliesPage,
          {
            id: commentId,
            pageNumber: 1,
          },
        ]);
        pageNumber += 1;
      }
    } else {
      setRepliesPage([
        ...repliesPage,
        {
          id: commentId,
          pageNumber: 1,
        },
      ]);
      pageNumber += 1;
    }
    axiosRequest
      .get(`post/getreplycomment/${commentId}?page=${pageNumber}`)
      .then((res) => {
        var replies_append = appendPostComments.map((post_comment) => {
          if (post_comment.commentId === commentId) {
            post_comment.replies = removeDuplicatesIdData([
              ...post_comment.replies,
              ...res.data.data,
            ]);
            return post_comment;
          }
          return post_comment;
        });
        setAppendPostComments(replies_append);
        if (repliesPageCount !== 0) {
          setRepliesPageCount(repliesPageCount - res.data.data.length);
        } else {
          setRepliesPageCount(res.data.totalCount - res.data.data.length);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const removeDuplicatesIdData = (arrData) => {
    let uniqueCommentIds = new Set();
    let arrayWithUniqueComments = arrData.filter((obj) => {
      if (!uniqueCommentIds.has(obj._id)) {
        uniqueCommentIds.add(obj._id);
        return true;
      }
      return false;
    });
    return arrayWithUniqueComments;
  };

  const toggleComment = (postId, cmtId) => {
    const data = {
      commentId: cmtId,
    };
    axiosRequest
      .put(`post/comment/like/${postId}`, data)
      .then((res) => {})
      .catch((error) => {
        console.log(error);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleCancelReply = () => {
    setToReply(false);
    setToReplyId();
    setToReplyName();
  };

  const handleInputChange = (e) => {
    setWriteComment(e.target.textContent);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      commentAndRepliesDataAppend();
    }
  };

  const whySeenPost = () => {
    setPostMenu(false);
    setPostWhy(true);
  };

  const goToSlide = (index) => {
    sliderRef?.current?.slickGoTo(index);
  };

  const handleClickOutside = (event) => {
    // Check if click is directly on profileView, not on profileView-wrapper
    if (profilePostViewRef.current === event.target) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useSwiperSlider(sliderRef, sliderWrapperRef, post.media.length);

  const toggleLike = (postId) => {
    axiosRequest
      .put(`post/like/${postId}`)
      .then((res) => {
        if (res?.data?.message === LIKED || res?.data?.message === UNLIKE) {
          if (res?.data?.message === LIKED) {
            const updatedUser = {
              ...user,
              Teps: user?.Teps + 1,
            };
            dispatch(setUser(updatedUser));
          }
          if (res?.data?.message === UNLIKE) {
            const updatedUser = {
              ...user,
              Teps: user?.Teps - 1,
            };
            dispatch(setUser(updatedUser));
          }
          const newPost = post;

          if (res?.data?.message === LIKED) {
            newPost.selfLike = true;
            newPost.TotalLike += 1;
            newPost.likeUser = [
              ...(newPost.likeUser || []),
              {
                userId: {
                  _id: User?._id,
                  name: "You",
                  profile: User?.profile,
                  phone: User?.phone,
                  countryCode: User?.countryCode,
                  contactStatus: User?.isUserVerified,
                },
              },
            ];
          } else if (res?.data?.message === UNLIKE) {
            newPost.selfLike = false;
            newPost.TotalLike = Math.max(0, newPost.TotalLike - 1);
            newPost.likeUser = newPost.likeUser?.filter(
              (item) => item?.userId?._id !== UserID
            );
          }
          dispatch(setPostDetails(newPost));
          const findPost = Tepars.map((Post) =>
            Post?._id === postId ? newPost : Post
          );
          dispatch(setTepars(findPost));

          if (newPost.selfLike) {
            setShowAnimation(true);
            setTimeout(() => {
              setShowAnimation(false);
            }, 1000);
          }
        } else {
          dispatch(handleToast(res?.data?.message));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status !== 401) {
          dispatch(handleToast(ERROR));
        }
      });
  };

  const handleDoubleClick = () => {
    if (!post.selfLike) {
      toggleLike(post._id);
    } else {
      setShowAnimation(true);
      setTimeout(() => {
        setShowAnimation(false);
      }, 1000);
    }
  };

  const openGoogleMaps = () => {
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${post?.location?.latitude},${post?.location?.longitude}`;
    window.open(googleMapsURL, "_blank");
  };

  useEffect(() => {
    if (post?.media?.[mediaIndexFinal]?.type === "Video") {
      setMediaAudioShow(true);
    } else {
      setMediaAudioShow(false);
    }
  }, [post, mediaIndexFinal]);

  return (
    <div className="profilePostView" ref={profilePostViewRef}>
      <div className="profilePostView-wrapper" ref={containerRef} role="dialog">
        <div
          className="container"
          ref={sliderWrapperRef}
          onDoubleClick={handleDoubleClick}
        >
          <div
            className="upload-image-container"
            style={{
              // width: "450px",
              // height: "562.5px",
              aspectRatio: post.media[0].aspectRatio,
            }}
          >
            <SliderPopup
              sliderWrapperRef={sliderWrapperRef}
              post={post}
              imagesList={imagesList}
              mediaIndexFinal={mediaIndexFinal}
              setMediaIndexFinal={setMediaIndexFinal}
            />
            {/* <Slider
              ref={sliderRef}
              {...{
                infinite: false,
                speed: 500,
                slidesToShow: 1,
                swipe: false,
                afterChange: handleSlideChange,
              }}
            >
              {post?.media?.map((images, index) =>
                images?.type === IMAGE ? (
                  <div className="next-img" key={index}>
                    <div
                      className="upload-image"
                      key={index}
                      style={{
                        backgroundPosition: "center",
                        width: "100%",
                        height: "100%",
                        backgroundImage: `url(${
                          post_image__url + images?.url
                        })`,
                        backgroundSize:
                          dimensions.width > dimensions.height
                            ? "contain"
                            : dimensions.width === dimensions.height
                            ? "contain"
                            : "cover",
                      }}
                    />
                  </div>
                ) : images?.type === VIDEO ? (
                  <>
                    <div
                      className="upload-image"
                      key={index}
                      style={{ position: "relative" }}
                    >
                      <video
                        id={`${images?._id}-${index}`}
                        name={`${images?._id}-${index}`}
                        muted={sound}
                        className="upload-video"
                        src={getTepars(images)}
                        ref={videoRef}
                        loop
                        autoplay
                        controls={false}
                        width="100%"
                        height="100%"
                        onLoadedMetadata={handleLoadedMetadata}
                        onClick={(e) => {
                          handlePlayPauseToggleClick(`${images?._id}-${index}`);
                        }}
                        style={{
                          objectFit: getObjectFit(),
                          cursor: "pointer",
                        }}
                      />

                      {videoPause && images?.type === "Video" && (
                        <div
                          className={`video ${videoPause}`}
                          onClick={(e) => {
                            handlePlayPauseToggleClick(
                              `${images?._id}-${index}`
                            );
                          }}
                          onContextMenu={disableRightClick}
                          title="Play"
                        >
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <title>Play</title>
                            <g id="line-md:play-to-pause-transition">
                              <path
                                id="Vector"
                                d="M1.83399 11.0104L1.83398 17.8482C1.83398 19.6325 3.97888 20.7407 5.69234 19.8541L12.2873 16.4297L18.8822 13.0053C20.5957 12.1187 20.5957 9.89112 18.8822 8.99345L12.2873 5.56901L5.69234 2.14456C3.97889 1.25798 1.83399 2.36621 1.83399 4.15047L1.83399 10.9883L1.83399 11.0104Z"
                                fill="white"
                              />
                            </g>
                          </svg>
                        </div>
                      )}
                    </div>
                  </>
                ) : null
              )}
            </Slider> */}
            {imagesList?.length > 1 && (
              <div className="image-number">
                <span className="number">
                  {`${mediaIndexFinal + 1}/${imagesList?.length}`}
                </span>
              </div>
            )}
            {mediaAudioShow && (
              <div
                className="image-sound"
                onClick={() => dispatch(setSound(!sound))}
              >
                {sound ? (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Unmute</title>
                    <g id="wpf:mute">
                      <path
                        id="Vector"
                        d="M4.31237 2.46138C4.08714 2.50214 3.87773 2.6049 3.70768 2.75812C3.53764 2.91134 3.41369 3.10894 3.34976 3.32873C3.28583 3.54851 3.28446 3.78177 3.3458 4.00229C3.40713 4.22281 3.52875 4.42186 3.69699 4.57707L28.3124 29.1925C28.421 29.3264 28.5565 29.4361 28.7102 29.5143C28.8639 29.5925 29.0323 29.6375 29.2046 29.6465C29.3768 29.6554 29.549 29.6281 29.71 29.5662C29.8709 29.5043 30.0171 29.4093 30.139 29.2873C30.2609 29.1653 30.3558 29.0191 30.4176 28.8581C30.4793 28.6971 30.5065 28.5249 30.4975 28.3526C30.4884 28.1804 30.4433 28.012 30.3649 27.8584C30.2866 27.7047 30.1769 27.5693 30.0428 27.4608L20.9278 18.3469V4.07615C20.9278 2.51922 19.5678 2.12907 18.5044 3.19245L12.081 9.50015L5.42868 2.84538C5.30164 2.71007 5.14564 2.60524 4.97236 2.53874C4.79908 2.47224 4.61301 2.44579 4.42806 2.46138C4.38952 2.45956 4.35091 2.45956 4.31237 2.46138ZM6.15852 11.1543C5.48037 11.1543 4.92776 11.7057 4.92776 12.3851V19.7697C4.92776 20.4466 5.48037 21.0005 6.15852 21.0005H10.3899L18.4662 28.8835C19.697 30.1143 20.9278 29.4841 20.9278 27.8066V24.6521L7.42745 11.1543H6.15852Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg viewBox="0 0 32 32" fill="none">
                    <title>Mute</title>
                    <g clipPath="url(#clip0_1657_59)">
                      <g filter="url(#filter0_d_1657_59)">
                        <path
                          d="M2.9959 21.9013L2.97233 11.224H10.9391L16.2895 5.87351L16.3131 27.2517L10.9626 21.9013H2.9959ZM18.9058 28.1945V25.4604C20.9014 24.879 22.5121 23.7636 23.7377 22.1143C24.9634 20.4651 25.5762 18.6027 25.5762 16.5273C25.5762 14.4531 24.9634 12.5913 23.7377 10.9421C22.5121 9.29277 20.9014 8.1768 18.9058 7.59414L18.9058 4.85999C21.6557 5.47282 23.9027 6.86378 25.6469 9.03287C27.3911 11.202 28.2632 13.7001 28.2632 16.5273C28.2632 19.3557 27.3911 21.8544 25.6469 24.0235C23.9027 26.1926 21.6557 27.5829 18.9058 28.1945ZM18.9058 22.6319L18.9058 19.4264C19.2986 19.0336 19.6208 18.5939 19.8722 18.1074C20.1236 17.6209 20.2493 17.1099 20.2493 16.5744C20.2493 16.0087 20.1277 15.4785 19.8844 14.9839C19.6412 14.4892 19.315 14.0373 18.9058 13.6281L18.9058 10.4226C20.0686 11.0511 21.0271 11.8996 21.7814 12.9682C22.5356 14.0367 22.9127 15.2309 22.9127 16.5508C22.9127 17.8708 22.5356 19.0574 21.7814 20.1109C21.0271 21.1643 20.0686 22.0047 18.9058 22.6319Z"
                          fill="white"
                        />
                      </g>
                    </g>
                    <defs>
                      <filter
                        id="filter0_d_1657_59"
                        x="-1.02734"
                        y="2.85938"
                        width="33.291"
                        height="31.3359"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          result="hardAlpha"
                        />
                        <feOffset dy="2" />
                        <feGaussianBlur stdDeviation="2" />
                        <feComposite in2="hardAlpha" operator="out" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                        />
                        <feBlend
                          mode="normal"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow_1657_59"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow_1657_59"
                          result="shape"
                        />
                      </filter>
                      <clipPath id="clip0_1657_59">
                        <rect width="32" height="32" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )}
              </div>
            )}
            {/* Dots */}
            <div className="cta-wrapper" style={{ justifyContent: "center" }}>
              <div className="add-tepars-container">
                <div className="add-tepars">
                  {post?.media?.length > 1 && (
                    <ShowDots
                      media={post?.media}
                      mediaIndexFinal={mediaIndexFinal}
                      getSelectedImage={setSelectedTeparsImage}
                      getCurrentIndex={setGetCurrentIndex}
                      setRightClick={setRightClick}
                      checkRightClick={rightClick}
                      setLeftClick={setLeftClick}
                      checkLeftClick={leftClick}
                      goToSlide={goToSlide}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="gradient" aria-hidden="true" />
          </div>

          {showAnimation && (
            <div className="doubleTepAnimation">
              <img src={BigTep} alt="Teps" />
            </div>
          )}
        </div>
        <div className="profilePostView-details">
          {post?.RepostPostId && (
            <div className="reposted-notification">
              <div className="reposted-notification-wrapper">
                <div className="repost-image">
                  <svg width="18" height="18" viewBox="0 0 24 24" fill="none">
                    <title>Repost</title>
                    <g id="Iconsax/Linear/messagetext">
                      <g id="Group 1261154220">
                        <g id="Group">
                          <path
                            id="Vector"
                            d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_2"
                            d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                        <g id="Group_2">
                          <path
                            id="Vector_3"
                            d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            id="Vector_4"
                            d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                            stroke={
                              theme === "Light"
                                ? "#686868"
                                : theme === "Dark"
                                ? "#999999"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#686868"
                                : "#999999"
                            }
                            strokeWidth="1.37293"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </div>
                {post?.RepostPostId?.circleData?.groupType === "Circle" ? (
                  <span className="text">
                    {isSelfPost ? "You reposted" : "Reposted"}
                    <span
                      className="reposted-user-name"
                      onClick={() => setShowReplyPopup(true)}
                    >
                      @
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM7.00017 10.5446C8.95807 10.5446 10.5453 8.95742 10.5453 6.99952C10.5453 5.04162 8.95807 3.45443 7.00017 3.45443C5.04227 3.45443 3.45508 5.04162 3.45508 6.99952C3.45508 8.95742 5.04227 10.5446 7.00017 10.5446Z"
                          fill="url(#paint0_linear_8049_744)"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_8049_744"
                            x1="13.9979"
                            y1="13.998"
                            x2="-2.30878"
                            y2="-2.43178"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#5259D7" />
                            <stop offset="0.278608" stop-color="#1891A2" />
                            <stop offset="0.564171" stop-color="#1891A2" />
                            <stop offset="0.856489" stop-color="#27D6B7" />
                          </linearGradient>
                        </defs>
                      </svg>
                      {post?.RepostPostId?.circleData?.groupName}
                    </span>
                    circle post
                  </span>
                ) : isSelfPost ? (
                  <span className="text">
                    You reposted
                    <span className="reposted-user-name">
                      @{post?.RepostPostId?.userId?.name}
                    </span>
                    post
                  </span>
                ) : (
                  <span className="text">Reposted</span>
                )}
              </div>
            </div>
          )}
          <div className="header">
            <div className="profile">
              <div className="profile-image" tabIndex="0">
                <img
                  className="profile-avatar"
                  src={
                    isNormalOrNormalGroup
                      ? getUserProfilePic(post?.userId?.profile)
                      : getSponsorProfilePic(
                          post?.group[0]?.GroupId?.groupProfile
                        )
                  }
                  alt="Profile Avatar"
                />
              </div>
              <div className="profile-info">
                {isNormalOrNormalGroup ? (
                  <div className="profile-info-wrapper">
                    <div className="profile-info-name">
                      <span className="name" tabIndex="0">
                        {getSelfUser(post.userId)}
                      </span>
                      {post?.location?.name && (
                        <>
                          <span> is in </span>
                          <span
                            className="location-name"
                            tabIndex="0"
                            onClick={() => openGoogleMaps()}
                          >
                            <svg
                              width="13"
                              height="15"
                              viewBox="0 0 13 15"
                              fill="none"
                              className="location-icon"
                            >
                              <path
                                d="M6.5 0.9375C4.25725 0.9375 2.4375 2.82744 2.4375 5.15625C2.4375 8.90625 6.5 14.0625 6.5 14.0625C6.5 14.0625 10.5625 8.90625 10.5625 5.15625C10.5625 2.82744 8.74275 0.9375 6.5 0.9375ZM6.5 7.5C6.17861 7.5 5.86443 7.39003 5.5972 7.18401C5.32997 6.97798 5.12169 6.68514 4.9987 6.34253C4.8757 5.99992 4.84352 5.62292 4.90622 5.25921C4.96892 4.89549 5.12369 4.5614 5.35095 4.29917C5.57821 4.03695 5.86776 3.85837 6.18298 3.78603C6.4982 3.71368 6.82493 3.75081 7.12186 3.89273C7.41879 4.03464 7.67258 4.27496 7.85114 4.58331C8.0297 4.89165 8.125 5.25416 8.125 5.625C8.12453 6.12211 7.95317 6.59871 7.64853 6.95023C7.34388 7.30174 6.93083 7.49946 6.5 7.5Z"
                                stroke="#1891A2"
                              />
                            </svg>
                            {post?.location?.name}
                          </span>
                        </>
                      )}
                    </div>

                    <div className="profile-details">
                      <p className="profile-details-time">
                        {convertToAgoFormat(post?.createAt)}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="profile-info-wrapper">
                    <div className="profile-details">
                      <div className="circle-image">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14ZM6.99952 10.5446C8.95742 10.5446 10.5446 8.95742 10.5446 6.99952C10.5446 5.04162 8.95742 3.45443 6.99952 3.45443C5.04162 3.45443 3.45443 5.04162 3.45443 6.99952C3.45443 8.95742 5.04162 10.5446 6.99952 10.5446Z"
                            fill={
                              theme === "Light"
                                ? "#2a2a2a"
                                : theme === "Dark"
                                ? "#ffffff"
                                : window.matchMedia(
                                    "(prefers-color-scheme: light)"
                                  ).matches
                                ? "#2a2a2a"
                                : "#ffffff"
                            }
                          />
                        </svg>
                      </div>
                      <span
                        className="name"
                        onClick={() => setShowReplyPopup(true)}
                      >
                        {post?.group[0]?.GroupId?.groupName}
                      </span>
                    </div>
                    <div className="profile-details">
                      <p className="profile-details-time">
                        {convertToAgoFormat(post?.createAt)}
                      </p>
                    </div>
                  </div>
                )}
                <button
                  className="btn profile-cta"
                  title="More options"
                  onClick={() => setPostMenu(true)}
                >
                  <svg width="6" height="16" viewBox="0 0 6 24" fill="none">
                    <path
                      d="M3 0.75C1.625 0.75 0.5 1.875 0.5 3.25C0.5 4.625 1.625 5.75 3 5.75C4.375 5.75 5.5 4.625 5.5 3.25C5.5 1.875 4.375 0.75 3 0.75ZM3 18.25C1.625 18.25 0.5 19.375 0.5 20.75C0.5 22.125 1.625 23.25 3 23.25C4.375 23.25 5.5 22.125 5.5 20.75C5.5 19.375 4.375 18.25 3 18.25ZM3 9.5C1.625 9.5 0.5 10.625 0.5 12C0.5 13.375 1.625 14.5 3 14.5C4.375 14.5 5.5 13.375 5.5 12C5.5 10.625 4.375 9.5 3 9.5Z"
                      fill={
                        theme === "Light"
                          ? "#2a2a2a"
                          : theme === "Dark"
                          ? "#ffffff"
                          : window.matchMedia("(prefers-color-scheme: light)")
                              .matches
                          ? "#2a2a2a"
                          : "#ffffff"
                      }
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="commentView-wrapper">
            <div
              onScroll={handleScroll}
              ref={containerRef}
              className="comment-section-wrapper"
            >
              <div className="post-comments">
                {commentLoading ? (
                  <div className="no-data">
                    <Spinner />
                  </div>
                ) : (
                  <>
                    {appendPostComments && appendPostComments.length > 0 ? (
                      <>
                        {appendPostComments.map((comment, index) => (
                          <div className="commented-user" key={index}>
                            <div className="comment-user-profile">
                              <div className="profile-image">
                                <img
                                  src={getUserProfilePic(
                                    comment?.userId.profile
                                  )}
                                  alt="comment user profile"
                                />
                              </div>
                              <div className="profile-info-wrapper">
                                <div className="profile-info">
                                  <div className="profile-name">
                                    <span className="name">
                                      {comment?.userId?._id === UserID
                                        ? "You"
                                        : getSelfUser(comment?.userId)}
                                    </span>
                                    <span className="commented-time">
                                      {convertToAgoFormat(comment?.commentAt)}
                                    </span>
                                  </div>
                                  <div className="commented-comment">
                                    <p className="comment">{comment?.text}</p>
                                  </div>
                                </div>
                                <CommentLikeAndCount
                                  postData={post}
                                  comment={comment}
                                  toggleComment={toggleComment}
                                  handleReplyClick={handleReplyClick}
                                  commentActions={commentActions}
                                />
                              </div>
                            </div>
                            {comment?.replies && (
                              <>
                                {comment.replies.map((reply, replyIndex) => (
                                  <div
                                    className="commented-replys"
                                    key={replyIndex}
                                  >
                                    {reply && (
                                      <div className="comment-user-profile">
                                        <div className="profile-image">
                                          <img
                                            src={getUserProfilePic(
                                              reply?.userId.profile
                                            )}
                                            alt="comment user profile"
                                          />
                                        </div>
                                        <div className="profile-info-wrapper">
                                          <div className="profile-info">
                                            <div className="profile-name">
                                              <span className="name">
                                                {reply?.userId?._id === UserID
                                                  ? "You"
                                                  : getSelfUser(reply?.userId)}
                                              </span>
                                              <span className="commented-time">
                                                {convertToAgoFormat(
                                                  reply?.commentAt
                                                )}
                                              </span>
                                            </div>
                                            <div className="commented-comment">
                                              <p className="comment">
                                                <span className="replied-user-name">
                                                  @{reply?.taguser?.name}
                                                </span>
                                                {reply?.text}
                                              </p>
                                            </div>
                                            <RepliesLikeAndCount
                                              index={replyIndex}
                                              postData={post}
                                              comment={comment}
                                              reply={reply}
                                              handleReplyClick={
                                                handleReplyClick
                                              }
                                              toggleCommentReply={
                                                toggleCommentReply
                                              }
                                              replyActions={replyActions}
                                              handleViewMoreReplies={
                                                handleViewMoreReplies
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ))}
                                {comment.replyCount >
                                  comment.replies.length && (
                                  <div
                                    className="view-replys"
                                    onClick={() =>
                                      handleViewMoreReplies(comment?.commentId)
                                    }
                                  >
                                    <span className="text">
                                      View{" "}
                                      {comment.replyCount -
                                        comment.replies.length}{" "}
                                      more
                                      {comment.replies.length === 0
                                        ? " replies"
                                        : " reply"}
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="no-comments">
                        <div className="no-comment-image">
                          <svg
                            width="45"
                            height="45"
                            viewBox="0 0 56 56"
                            fill="none"
                          >
                            <g id="Iconsax/Linear/messagetext">
                              <g id="Group 34106">
                                <path
                                  id="Vector"
                                  d="M16.1191 21.3203H39.9828M16.1191 34.5779H32.8237"
                                  stroke={
                                    theme === "Light"
                                      ? "#2a2a2a"
                                      : theme === "Dark"
                                      ? "#ffffff"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#2a2a2a"
                                      : "#ffffff"
                                  }
                                  strokeWidth="2.25"
                                  strokeMiterlimit="10"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_2"
                                  d="M28.0014 53.456C33.0358 53.456 37.9572 51.9631 42.1432 49.1661C46.3292 46.3691 49.5917 42.3937 51.5183 37.7425C53.4449 33.0912 53.949 27.9732 52.9668 23.0355C51.9847 18.0978 49.5604 13.5622 46.0005 10.0023C42.4406 6.44247 37.905 4.01816 32.9673 3.03599C28.0296 2.05382 22.9116 2.5579 18.2604 4.4845C13.6092 6.41109 9.63372 9.67366 6.83674 13.8596C4.03976 18.0456 2.54688 22.967 2.54688 28.0014C2.54688 32.2099 3.56506 36.1752 5.37516 39.6737L2.54688 53.456L16.3291 50.6277C19.8248 52.435 23.7958 53.456 28.0014 53.456Z"
                                  stroke={
                                    theme === "Light"
                                      ? "#2a2a2a"
                                      : theme === "Dark"
                                      ? "#ffffff"
                                      : window.matchMedia(
                                          "(prefers-color-scheme: light)"
                                        ).matches
                                      ? "#2a2a2a"
                                      : "#ffffff"
                                  }
                                  strokeWidth="2.25"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </g>
                          </svg>
                        </div>
                        <span className="text">No comments yet</span>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* <Comments /> */}
          <div className="profilePostView-cta">
            <div className="post-media-cta">
              <div className="media-cta">
                {/* Like */}
                <button
                  className="btn btn-like"
                  onClick={() => toggleLike(post?._id)}
                >
                  <img
                    src={
                      post?.selfLike
                        ? like
                        : theme === "Light" ||
                          (theme !== "Dark" &&
                            window.matchMedia("(prefers-color-scheme: light)")
                              .matches)
                        ? unlike
                        : unlikeGrey
                    }
                    className={post?.selfLike ? "animate" : ""}
                    title={post?.selfLike ? "Unteps" : "Teps"}
                    alt="Teps"
                  />
                </button>
                {/* Comment */}
                <button
                  className="btn btn-comment"
                  onClick={() => fileInputRef.current.focus()}
                >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <title>Comment</title>
                    <g id="Iconsax/Linear/messagetext">
                      <g id="Group 34106">
                        <path
                          id="Vector"
                          d="M7.2793 9.35156H16.745M7.2793 14.6112H13.9053"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeMiterlimit="10"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          id="Vector_2"
                          d="M11.9932 22.1033C13.9902 22.1033 15.9423 21.5111 17.6027 20.4014C19.2631 19.2918 20.5572 17.7146 21.3214 15.8693C22.0856 14.0241 22.2856 11.9936 21.896 10.0347C21.5064 8.07574 20.5448 6.27636 19.1327 4.86405C17.7207 3.45174 15.9216 2.48995 13.963 2.1003C12.0044 1.71064 9.97431 1.91063 8.12937 2.67496C6.28443 3.43929 4.70754 4.73365 3.59809 6.39435C2.48865 8.05504 1.89648 10.0075 1.89648 12.0048C1.89648 13.6744 2.30035 15.2476 3.01835 16.6355L1.89648 22.1033L7.36331 20.9813C8.74993 21.6983 10.325 22.1033 11.9932 22.1033Z"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </g>
                  </svg>
                </button>
                {/* Reply */}
                {post?.canReply && !isAdminPost && (
                  <button
                    ref={replyRef}
                    className="btn btn-reply"
                    onClick={() => setShowReplyPopup(true)}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <title>Reply</title>
                      <g id="Frame 121">
                        <path
                          id="Vector"
                          d="M20.9889 9.74951L14.9783 2.63363C14.8038 2.42713 14.5915 2.26216 14.3549 2.14917C14.1184 2.03618 13.8626 1.97765 13.604 1.97729C13.3454 1.97693 13.0895 2.03475 12.8526 2.14707C12.6157 2.2594 12.403 2.42379 12.2279 2.6298L6.19658 9.7289C5.0976 11.0227 5.94369 13.1127 7.568 13.1149L10.7896 13.1194C10.7825 18.1674 1.44786 21.6879 1.44786 21.6879C1.44786 21.6879 16.3734 23.2231 16.3874 13.1272L19.6099 13.1317C21.2333 13.134 22.0843 11.0463 20.9889 9.74951Z"
                          stroke={
                            theme === "Light"
                              ? "#686868"
                              : theme === "Dark"
                              ? "#999999"
                              : window.matchMedia(
                                  "(prefers-color-scheme: light)"
                                ).matches
                              ? "#686868"
                              : "#999999"
                          }
                          strokeWidth="1.37293"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  </button>
                )}
              </div>
              {/* Re-post */}
              {post?.settingRepost && (
                <div className="repost">
                  <button
                    className="btn btn-repost"
                    onClick={() => handleRepostModal()}
                  >
                    <svg width="14" height="14" viewBox="0 0 24 24" fill="none">
                      <title>Repost</title>
                      <g id="Iconsax/Linear/messagetext">
                        <g id="Group 1261154220">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M19.9523 11.9214V9.19735C19.9523 8.11367 19.5218 7.07437 18.7555 6.3081C17.9893 5.54182 16.95 5.11133 15.8663 5.11133H4.97021"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Vector_2"
                              d="M9.0537 9.19158L4.96631 5.10556L9.0537 1.01953"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                          <g id="Group_2">
                            <path
                              id="Vector_3"
                              d="M4.96566 12.0005V14.7245C4.96566 15.8082 5.39615 16.8475 6.16243 17.6138C6.92871 18.3801 7.96801 18.8105 9.05169 18.8105H19.9478"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              id="Vector_4"
                              d="M15.8643 14.7303L19.9517 18.8163L15.8643 22.9023"
                              stroke={
                                theme === "Light"
                                  ? "#686868"
                                  : theme === "Dark"
                                  ? "#999999"
                                  : window.matchMedia(
                                      "(prefers-color-scheme: light)"
                                    ).matches
                                  ? "#686868"
                                  : "#999999"
                              }
                              strokeWidth="1.37293"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </button>
                  <span className="repost-number">{post?.countRepost}</span>
                </div>
              )}
            </div>

            <div className="post-report">
              {post?.TotalLike > 0 && (
                <div
                  className="users-liked"
                  tabIndex="0"
                  onClick={() => {
                    setPostLikes(true);
                    setSelectPostID(post?._id);
                  }}
                >
                  {!isAdminPost && (!post?.group || !isCircleGroupMember) && (
                    <div className="users-profiles-wrapper">
                      {post?.likeUser?.slice(0, 3).map((likes, index) => {
                        return (
                          <div key={index} className="users-profile">
                            <img
                              src={getUserProfilePic(likes?.userId.profile)}
                              alt="profile"
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {renderLikeInfo()}
                </div>
              )}
            </div>

            <div className="footer-box">
              {toReply && (
                <div className="replying-to-wrapper">
                  <div className="reply-to">
                    <p className="reply-to-name">
                      Replying to
                      <a href="/" className="name">
                        {toTagUserName !== undefined
                          ? toTagUserName
                          : toReplyName}
                      </a>
                    </p>
                    <span
                      className="cancel-reply"
                      role="button"
                      onClick={() => handleCancelReply()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
              <div className="comment-to-post">
                <div className="message-wrapper">
                  <div className="input-wrapper">
                    <div
                      ref={fileInputRef}
                      placeholder="Add a comment..."
                      className="messageInput"
                      contentEditable
                      onInput={handleInputChange}
                      onKeyDown={handleKeyPress}
                    />
                  </div>
                </div>
                <div
                  role="button"
                  className="send-message"
                  onClick={writeComment ? commentAndRepliesDataAppend : null}
                >
                  <svg width="46" height="46" viewBox="0 0 46 46" fill="none">
                    <title>Send</title>
                    <rect
                      width="46"
                      height="46"
                      rx="23"
                      transform="matrix(-1 0 0 1 46 0)"
                      fill="#1891A2"
                    />
                    <path
                      d="M18.8886 31.6163L26.34 24.3652C27.22 23.5088 27.22 22.1075 26.34 21.2512L18.8886 14"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        className="btn btn-close"
        onClick={() => {
          onClose();
          // dispatch(setPostDetails(null));
        }}
      >
        <svg width="24" height="24" viewBox="0 0 67 64" fill="none">
          <title>Close</title>
          <path
            d="M53.1262 12.1576L53.3919 12.3897C54.6728 13.628 54.7529 15.5875 53.6321 16.9159L53.3919 17.1728L38.0532 32.0002L53.3919 46.8276C54.6728 48.0658 54.7529 50.0253 53.6321 51.3537L53.3919 51.6106C52.1109 52.8488 50.0839 52.9262 48.7096 51.8428L48.4439 51.6106L33.1052 36.7832L17.7665 51.6106C16.4856 52.8488 14.4585 52.9262 13.0843 51.8428L12.8186 51.6106C11.5376 50.3724 11.4576 48.4128 12.5784 47.0844L12.8186 46.8276L28.1573 32.0002L12.8186 17.1728C11.5376 15.9345 11.4576 13.975 12.5784 12.6466L12.8186 12.3897C14.0995 11.1515 16.1266 11.0741 17.5008 12.1576L17.7665 12.3897L33.1052 27.2171L48.4439 12.3897C49.7249 11.1515 51.7519 11.0741 53.1262 12.1576L53.3919 12.3897L53.1262 12.1576Z"
            fill="white"
          />
        </svg>
      </button>

      {/* <button className="btn btn-backwards" title="Backward">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <title>Backward</title>
          <g clipPath="url(#clip0_2240_1583)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.29559 11.2932C8.10812 11.4807 8.0028 11.735 8.0028 12.0002C8.0028 12.2653 8.10812 12.5197 8.29559 12.7072L13.9526 18.3642C14.0448 18.4597 14.1552 18.5359 14.2772 18.5883C14.3992 18.6407 14.5304 18.6683 14.6632 18.6694C14.796 18.6706 14.9276 18.6453 15.0505 18.595C15.1734 18.5447 15.2851 18.4705 15.379 18.3766C15.4729 18.2827 15.5471 18.171 15.5974 18.0481C15.6477 17.9252 15.673 17.7936 15.6718 17.6608C15.6707 17.528 15.6431 17.3968 15.5907 17.2748C15.5383 17.1528 15.4621 17.0424 15.3666 16.9502L10.4166 12.0002L15.3666 7.05018C15.5487 6.86158 15.6495 6.60898 15.6473 6.34678C15.645 6.08458 15.5398 5.83377 15.3544 5.64836C15.169 5.46295 14.9182 5.35778 14.656 5.35551C14.3938 5.35323 14.1412 5.45402 13.9526 5.63618L8.29559 11.2932Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2240_1583">
              <rect
                width="24"
                height="24"
                fill="white"
                transform="matrix(-1 0 0 1 24 0)"
              />
            </clipPath>
          </defs>
        </svg>
      </button>

      <button className="btn btn-forward" title="Forward">
        <svg width="24" height="24" viewBox="0 0 30 30" fill="none">
          <title>Forward</title>
          <g clipPath="url(#clip0_2164_34396)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.6344 14.1175C19.8688 14.3519 20.0004 14.6697 20.0004 15.0012C20.0004 15.3327 19.8688 15.6505 19.6344 15.885L12.5632 22.9562C12.4479 23.0756 12.3099 23.1708 12.1574 23.2363C12.0049 23.3018 11.8409 23.3363 11.6749 23.3378C11.509 23.3392 11.3444 23.3076 11.1907 23.2447C11.0371 23.1819 10.8975 23.0891 10.7802 22.9717C10.6628 22.8543 10.57 22.7148 10.5071 22.5611C10.4443 22.4075 10.4127 22.2429 10.4141 22.077C10.4156 21.911 10.45 21.747 10.5155 21.5944C10.5811 21.4419 10.6763 21.304 10.7957 21.1887L16.9832 15.0012L10.7957 8.8137C10.568 8.57795 10.442 8.2622 10.4448 7.93445C10.4477 7.6067 10.5791 7.29319 10.8109 7.06143C11.0427 6.82967 11.3562 6.69821 11.6839 6.69536C12.0117 6.69251 12.3274 6.8185 12.5632 7.0462L19.6344 14.1175Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_2164_34396">
              <rect width="30" height="30" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button> */}

      {/* POSTLIKEUSER */}
      {postLikes && !isCircleGroupMember && (
        <PostLikeUser
          onClose={setPostLikes}
          selectPostID={selectPostID}
          setSelectPostID={setSelectPostID}
          setSelectedMenu={""}
        />
      )}

      {/* COMMENTACTION */}
      {isCommentOrReply && (
        <CommentAction
          passPostData={passPostData}
          setIsCommentOrReply={setIsCommentOrReply}
          isCommentId={isCommentId}
          setIsCommentId={setIsCommentId}
          isReplyId={isReplyId}
          setIsReplyId={setIsReplyId}
          isUserPost={isUserPost}
          setIsCommentDelete={setIsCommentDelete}
          setIsReplyDelete={setIsReplyDelete}
          setReportMenu={setReportMenu}
          setReportCommentAndReply={setReportCommentAndReply}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
        />
      )}
      {/* REPORTPOST */}
      {reportMenu && (
        <ReportPost
          post={passPostData}
          reportMenu={reportMenu}
          setReportMenu={setReportMenu}
          isCommentAndReplyId={isCommentAndReplyId}
          setIsCommentAndReplyId={setIsCommentAndReplyId}
          reportCommentAndReply={reportCommentAndReply}
          setReportCommentAndReply={setReportCommentAndReply}
        />
      )}
      {/* MENUPOPUP */}
      {postMenu && (
        <MenuPopup
          onClose={() => {
            setPostMenu(false);
          }}
          post={post}
          whySeenPost={(e) => whySeenPost(e)}
          repostPost={(e) => handleRepostModal(e)}
          setReportMenu={setReportMenu}
          setPostDetailsView={setPostDetailsView}
        />
      )}
      {/* COMINGSOON */}
      {showReplyPopup && (
        <ComingSoon onClose={() => setShowReplyPopup(false)} />
      )}
      {/* WHYPOST */}
      {postWhy && <WhyPost onClose={setPostWhy} userId={post?._id} />}
    </div>
  );
}

export default ProfilePostView;
